@media screen and (max-width: 600px) {

#testing-container {
    width: 80%;
    
    margin-top: 250px;
    background-color: #183319;
    border: 5px solid white;
    outline: 5px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#testing-title {
    margin: 5% 0 0;
}

#testing-subtitle {
    margin: 0;
    color: white;
}

.test-link {
    text-decoration: none;
}

.test-exit {
    width: 100%;
    text-align: center;
    color: black;
    font-size: 3rem;
    position: fixed;
    bottom: 0;
}

.test {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5000;
}
}

@media screen and (min-width: 601px) and (max-width: 924px) {
    #testing-container {
        width: 80%;
        
        margin-top: 250px;
        background-color: #183319;
        border: 5px solid white;
        outline: 5px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    #testing-title {
        margin: 5% 0 0;
    }
    
    #testing-subtitle {
        margin: 0;
        color: white;
    }

    .test-link {
        text-decoration: none;
    }
    
    .test-exit {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 3rem;
        position: fixed;
        bottom: 0;
    }
    
    .test {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 5000;
    }

}

@media screen and (min-width: 925px) {

    #testing-container {
        width: 80%;
        margin-top: 10%;
        margin-bottom: 10%;
        
        background-color: #183319;
        border: 5px solid white;
        outline: 5px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    #testing-title {
        margin: 5% 0 0;
    }
    
    #testing-subtitle {
        margin: 0;
        color: white;
    }

    .test-link {
        text-decoration: none;
        cursor: pointer;
    }
    
    .test-exit {
        width: 100%;
        text-align: center;
        color: black;
        font-size: 3rem;
        position: relative;
        bottom: 0;
        cursor: pointer;
    }
    
    .test {
        width: 80%;
        margin: 0 10%;
        position: relative;
        top: 0;
        z-index: 5000;
    }
}
