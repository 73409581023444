@media screen and (max-width: 600px) {
    .header-container {
        width: 100%;
        height: 75px;
        background-color: #183319;
        position: fixed;
        top: 0;
        z-index: 500;
    }

    .phone-header {
        width: 94%;
        height: 100%;
        margin: 0 3%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    #home-link {
        text-decoration: none;
    }
}

@media screen and (min-width: 925px) {
    .header-container {
        width: 100%;
        height: 75px;
        background-color: #183319;
        position: fixed;
        top: 0;
        z-index: 500;
    }

    .wide-header {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .header-links {
        text-decoration: none;
        width: 100px;
    }

    #header-home {
        width: 75px;
    }

    #header-home-logo {
        width: 100%;
    }
}