@media screen and (max-width: 600px) {
    .age-background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('https://drive.google.com/uc?id=1mCqG1gtNWw064Ug4XoRaQoTq9hG7HFIa');
        z-index: 1000;
    }

    .age-container {
        width: 80%;
        height: 40%;
        background-color: #183319;
        border: 5px solid white;
        outline: 5px solid black;

    }

    #age-question-text {
        font-size: 3.5rem;
        text-align: center;
        padding: 0 2.5%;
    }

    .age-answer {
        width: 75%;
        margin: 0 12.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

    }

    #age-answer-button {
        width: 100px;
        height: 50px;
    }
}

@media screen and (min-width: 900px) {
    .age-background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('https://drive.google.com/uc?id=1mCqG1gtNWw064Ug4XoRaQoTq9hG7HFIa');
        z-index: 1000;
    }

    .age-container {
        width: 60%;
        height: 50%;

        background-color: #183319;
        border: 10px solid white;
        outline: 10px solid black;
    }

    #age-question-text {
        font-size: 3.5rem;
        text-align: center;
    }

    .age-answer {
        width: 75%;
        margin: 0 12.5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

    }

    #age-answer-button {
        width: 150px;
        height: 75px;
    }

    #age-yes {
        font-size: 3rem;
    }

    #age-no {
        font-size: 3rem;
    }
}