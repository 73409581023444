.modalOverlay {
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 9999;
}

.modalWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.modal {
    position: fixed;
    
    background-color: white;


    width: 100%;
    height: 100%;
    pointer-events: all;
    overflow-y: scroll;
    
}