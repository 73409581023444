@media screen and (max-width: 600px) {
    #about-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #about {
        width: 80%;
        height: 40%;
        background-color: #183319;
        border: 5px solid white;
        outline: 5px solid black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #about-text {
        font-size: 2rem;
    }
}