@media screen and (max-width: 600px) {
    .menu-container {
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 70px;
     
        background-color: #183319;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        z-index: 400;
    }

    .menu-links {
        font-size: 2rem;
        text-decoration: none;

    }
}